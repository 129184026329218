<template>
  <ul class="flex">
    <li
      class="flex_col"
      @mouseenter="enter(index)"
      @mouseleave="leave"
      v-for="(item, index) in datalist"
      :key="index"
    >
      <template v-if="index + 1 !== defaultIndex">
        <img
          :src="item.typeImg"
          alt=""
          class="typeImg"
          v-if="index + 1 !== defaultIndex"
        />
        <div class="tips flex_col" v-if="index + 1 !== defaultIndex">
          <span class="tip">{{ item.tips }}</span>
          <span class="tip">“企业慧点”</span>
        </div>
      </template>
      <template v-else>
        <img :src="item.qr" alt="" class="qr qr-queue-bottom" />
      </template>
      <span class="mask">{{ item.mask }}</span>
      <img
        class="line"
        v-show="index + 1 < datalist.length"
        src="@/assets/images/finance/padding.png"
        alt=""
      />
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      datalist: [{
        typeImg: require('@/assets/images/finance/wx.png'),
        qr: require('@/assets/images/common/qr/wx.png'),
        tips: '微信小程序搜索',
        mask: '微信小程序'
      }, {
        typeImg: require('@/assets/images/finance/ios.png'),
        qr: require('@/assets/images/common/qr/ios.png'),
        tips: '苹果应用商店搜索',
        mask: 'iOS 版'
      }, {
        typeImg: require('@/assets/images/finance/android.png'),
        qr: require('@/assets/images/common/qr/android.png'),
        tips: '华为应用商店搜索',
        mask: 'Android 版'
      }],
      defaultIndex: null
    }
  },
  computed: {},
  methods: {
    enter (index) {
      this.defaultIndex = index + 1
    },
    leave () {
      this.defaultIndex = null
    }
  }
}

</script>
<style lang='scss' scoped>
ul {
  li {
    height: 356px;
    padding: 49px 0 52px 0;
    flex: 1;
    align-items: center;
    position: relative;
    .type {
      width: 90px;
      height: 90px;
    }
    .tips {
      margin-top: 32px;
      align-items: center;
      .tip {
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        color: #ffffff;
        white-space: nowrap;
      }
    }
    .mask {
      margin-top: 21px;
      font-size: 37px;
      font-weight: bold;
      line-height: 67px;
      white-space: nowrap;
      color: #dbbb95;
    }
  }
  li:nth-of-type(2) {
    .typeImg {
      width: 68px;
      height: 83px;
    }
    .tips {
      margin-top: 39px;
    }
  }
  li:nth-of-type(3) {
    .typeImg {
      width: 73px;
      height: 84px;
    }
    .tips {
      margin-top: 39px;
    }
  }
  .qr {
    width: 173px ;
    height: 181px ;
  }
}
.line {
  width: 0.55px !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
