
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['btnAction'],
  setup (props, context) {
    const btnAction = () => {
      context.emit('btnAction')
    }
    return {
      btnAction
    }
  }
})
