<template>
  <div class="base_btn pointer" @click="btnAction">免费体验</div>
</template>
<script lang='ts'>
import { defineComponent } from 'vue'
export default defineComponent({
  emits: ['btnAction'],
  setup (props, context) {
    const btnAction = () => {
      context.emit('btnAction')
    }
    return {
      btnAction
    }
  }
})
</script>
<style lang='scss' scoped>
.base_btn {
  width: 196px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: url(~assets/images/common/baseBtn2.png);
  border-radius: 27px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  transition: all 0.3s ease;
}
.base_btn:hover {
  background: url(~assets/images/common/baseBtn.png);
}
</style>
